import config from '@/config';
import { logger } from '@/helpers/logger';
import { useState } from 'react';
import { useMutation } from 'react-query';
import useAuthentication from '../useAuthentication/useAuthentication';
import useLocalStorage from '../useLocalStorage/useLocalStorage';
import { useSafeLayoutEffect } from '../useSafeLayoutEffect/useSafeLayoutEffect';
import { BenefitsState } from './types';

const useBenefits = () => {
  const [hasCheckedUserCoupons, setHasCheckedUserCoupons] = useState(false);
  const [state, dispatch] = useLocalStorage<BenefitsState>(
    'enercity@benefits',
    {}
  );
  const { user, isAuthenticated } = useAuthentication();

  const resetBenefits = () => {
    dispatch({});
  };

  const getUserCouponsMutationFn = async (): Promise<void> => {
    return fetch(`${config.api.baseUrl}benefits/my-coupons`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        ed4: user?.ed4,
        isu: user?.isu,
        email: user?.email,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw await response.json();
        }

        const data = await response.json();

        dispatch(data.requestedData.benefits);

        return data.requestedData.couponCode;
      })
      .catch((error) => {
        if (error instanceof Object) {
          const { httpStatus, errorMessage } = error;
          logger.error(`${httpStatus}: ${errorMessage}`);
          return;
        }

        logger.error(error);
      });
  };

  const { mutateAsync: getUserCoupons } = useMutation(getUserCouponsMutationFn);

  const mutationFn = async (dealId: string): Promise<string> => {
    return fetch(`${config.api.baseUrl}benefits/coupon`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        ed4: user?.ed4,
        isu: user?.isu,
        dealId,
        email: user?.email,
      }),
    }).then(async (response) => {
      if (!response.ok) {
        throw response;
      }

      const data = await response.json();

      dispatch({
        ...state,
        [data.requestedData.dealId]: data.requestedData.couponCode,
      });

      return data.requestedData.couponCode;
    });
  };

  const { mutateAsync: getCouponFromDeal } = useMutation(mutationFn);

  useSafeLayoutEffect(() => {
    if (isAuthenticated && !hasCheckedUserCoupons) {
      getUserCoupons();
      setHasCheckedUserCoupons(true);
    }
  }, [getUserCoupons, hasCheckedUserCoupons, isAuthenticated]);

  return {
    getCouponFromDeal,
    userCoupons: state ?? {},
    resetBenefits,
  };
};

export default useBenefits;
