import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { useEffect } from 'react';
import type { AklamioHelperProps } from './types';

const AklamioHelper = ({}: AklamioHelperProps) => {
  const { params } = useQueryParams();

  const aid = params.aid;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (!aid) return;

    const date = new Date();
    const futureDate = date.setDate(date.getDate() + 30);

    document.cookie = `aklamio.recommendation.e9185cf41d57d97b5752f30ed833fc2acae2d2a6d78cd812b170a3e36bba=${aid}; expires=${new Date(
      futureDate
    ).toUTCString()}; domain=.enercity.de; path=/;`;
  }, [aid]);

  return null;
};

export default AklamioHelper;
