import config from '@/config';
import { logger } from '@/helpers/logger';
import markerSDK, { MarkerSdk } from '@marker.io/browser';
import { useCallback, useEffect, useState } from 'react';
import useCommandKLogin from '../useCommandKLogin/useCommandKLogin';
import useLocalStorage from '../useLocalStorage/useLocalStorage';

const useLoadMarkerIo = () => {
  const [widget, setWidget] = useState<MarkerSdk | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoggedIn] = useCommandKLogin();
  const [canLoadWidget, setCanLoadWidget] = useLocalStorage('markerio', false);

  const handleLoadWidget = useCallback(async () => {
    if (!isLoggedIn && !canLoadWidget) {
      return;
    }

    try {
      const widget = await markerSDK.loadWidget({
        project: config.markerIoProject,
      });

      setWidget(widget);
      setIsLoaded(true);
    } catch (error) {
      logger.log(error);
    }
  }, [isLoggedIn, canLoadWidget]);

  const loadWidget = useCallback(() => {
    setCanLoadWidget(true);
  }, [setCanLoadWidget]);

  const unloadWidget = useCallback(() => {
    setCanLoadWidget(false);
    setWidget(null);
    setIsLoaded(false);
  }, [setCanLoadWidget]);

  useEffect(() => {
    if (isLoggedIn && !isLoaded && canLoadWidget) {
      handleLoadWidget();
    }
  }, [isLoaded, isLoggedIn, canLoadWidget, handleLoadWidget]);

  return { widget, isLoaded, loadWidget, unloadWidget };
};

export default useLoadMarkerIo;
