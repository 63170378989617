export enum HeadlineLevel {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum HeadlineColor {
  Light = 'light',
  Dark = 'dark',
  Gradient = 'gradient',
}

export enum HeadlineAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  NoAlign = 'noAlign',
}
