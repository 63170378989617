import endpoints from './endpoints';

export type ProjectName =
  | 'enercity'
  | 'ecg'
  | 'eeg'
  | 'eng'
  | 'esg'
  | 'esol'
  | 'mein-h-gas';

const config = { publicJsonDataDestPath: './public/json-data' };

const configByEnv = {
  local: {
    markerIoProject: '63b3029b852f45e8e494f4eb',
  },
  docker: {
    markerIoProject: '63b3029b852f45e8e494f4eb',
  },
  dev: {
    markerIoProject: '63b3029b852f45e8e494f4eb',
  },
  space: {
    markerIoProject: '63b3029b852f45e8e494f4eb',
  },
  stage: {
    markerIoProject: '63b302f502f52bcfbf7a07c5',
  },
  prod: {
    markerIoProject: '63b2d14802f52bcfbf79c6b1',
  },
} as const;

export const currentStage = (process.env.STAGE ||
  'local') as keyof typeof configByEnv;

const isPreProduction = ['docker', 'local', 'space'].includes(currentStage);

const currentEnvConfig = configByEnv[currentStage];

const configByProject = {
  enercity: {
    consentManagerId: '13851',
  },
  ecg: {
    consentManagerId: '33914',
  },
  eeg: {
    consentManagerId: '29612',
  },
  eng: {
    consentManagerId: '20905',
  },
  'mein-h-gas': {
    consentManagerId: '28448',
  },
  esg: {
    consentManagerId: undefined,
  },
  esol: {
    consentManagerId: '42241',
  },
};

export const currentProjectName = (process.env.PROJECT_NAME ||
  'enercity') as ProjectName;

const currentProjectConfig = configByProject[currentProjectName];

export const ASSET_BASE_URL =
  process.env.ASSETS_PATH || 'http://localhost:3000';

export const IMAGE_BASE_URL = process.env.ASSETS_PATH;

export default {
  currentProjectName,
  isPreProduction,
  ...currentEnvConfig,
  ...currentProjectConfig,
  ...{
    api: {
      baseUrl:
        (process.env.API_BASE_URL || 'http://localhost:3100/').replace(
          /\/$/,
          ''
        ) + '/',
    },
    googleKey: process.env.GOOGLE_API_KEY,
  },
  ...config,
} as const;

export { endpoints };
