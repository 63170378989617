import config from '../../config';

export enum UseScriptConfigVariant {
  Geocoding = 'geocoding',
  Aklamio = 'aklamio',
  CMPVendorList = 'cmpVendorList',
  CMPCookieInfo = 'cmpCookieInfo',
  EPilot = 'ePilot',
}

export const useScriptConfigs = {
  [UseScriptConfigVariant.Geocoding]: {
    src: `https://maps.googleapis.com/maps/api/js?key=${config.googleKey}&libraries=geocoding`,
  },
  [UseScriptConfigVariant.Aklamio]: {
    src: `https://api.aklamio.com/assets/embed/1.latest/embed.min.js`,
  },
  [UseScriptConfigVariant.CMPVendorList]: {
    src: `https://delivery.consentmanager.net/delivery/vendorlist.php?cdid=${config.consentManagerId}&l=de`,
  },
  [UseScriptConfigVariant.CMPCookieInfo]: {
    src: `https://delivery.consentmanager.net/delivery/cookieinfo.php?cdid=${config.consentManagerId}&l=de`,
  },
  [UseScriptConfigVariant.EPilot]: {
    src: `https://embed.journey.epilot.io/bundle.js`,
  },
};
